import { useEffect } from 'react'
// @ts-ignore
import OipfApplication from '@cbc/hbbtv-react-oipf-application'
import {
  broadcastVisible,
  BROADCAST_ELEMENT_ID,
} from '@components/shared/OipfApplication/broadcast'

let initialized = false
export function isInitialized() {
  return initialized
}

const useInitializeOipfApplication = (
  setInitialized: (initialized: boolean) => void,
) => {
  useEffect(() => {
    const isBrowser = typeof document !== 'undefined'
    if (!isBrowser) {
      return
    }

    const oipfApp = new OipfApplication(window, document, {
      videoElementId: BROADCAST_ELEMENT_ID,
      appMgrElementId: 'appmgr',
    })
    oipfApp.initApplication()
    oipfApp.initVideoplayer()
    oipfApp.registerKeys('ALL')
    ;(window as any).oipfApp = oipfApp
    setInitialized(true)
    initialized = true

    const appElement = document.getElementById('app')

    if (appElement) {
      appElement.style.zIndex = '50'
    }

    // show player after initializing to prevent "flickering" of the TV signal
    const player = document.getElementById(BROADCAST_ELEMENT_ID)

    if (player && broadcastVisible) {
      player.style.visibility = 'visible'
      try {
        ;(player as any).bindToCurrentChannel()
      } catch (e) {
        console.log(e)
      }
    }
  }, [setInitialized])
}

export default useInitializeOipfApplication
