import { useCallback, useEffect, useRef } from 'react'
import getConfig from 'next/config'
import { useRouter } from 'next/router'
import trackInfOnline from './infOnlineTracking'
import { hasTrackingConsent } from '@lib/utils/consent'
import useNavigationContext from '@lib/hooks/useNavigationContext'
import { useBroadcastInfo } from '@lib/hooks/useBroadcastInfo'

const { publicRuntimeConfig } = getConfig()
interface Offer {
  [key: string]: string
}
export interface TrackingConfiguration {
  offer: Offer
}

const defaultTrackingConfiguration: Partial<TrackingConfiguration> = {
  offer: {
    rtl: 'ctvrtlde',
  },
}

export function fromEnv(): Required<TrackingConfiguration> {
  return {
    ...defaultTrackingConfiguration,
    ...JSON.parse(
      publicRuntimeConfig.processEnv.NEXT_PUBLIC_TRACKING_CONFIGURATION || '',
    ),
  }
}

const trackingConfig: TrackingConfiguration = fromEnv()

export type Metadata = {
  agof: string
  ivw: string
}

const usePageTracking = (metadata: Metadata | undefined) => {
  const { previousRoute } = useNavigationContext()
  const comingBackFromArticleVideo = useRef<boolean>()
  comingBackFromArticleVideo.current =
    !!previousRoute && /\d{3}\/video/.test(previousRoute)

  const router = useRouter()
  const lastTrackedRoute = useRef<string>()

  const { station } = useBroadcastInfo()

  // NOTE: The effect is supposed to be run exactly once per page, but when
  // running the dev server locally, React strict mode is enabled, and therefore
  // components get mounted twice. This leads to a duplicate tracking call for
  // the very first page load. To properly check the actual tracking behavior,
  // use a build and run it locally.
  useEffect(() => {
    if (!metadata || !hasTrackingConsent()) {
      return
    }

    if (comingBackFromArticleVideo.current) {
      return
    }

    const asPathWithoutParams = router.asPath.split('?')[0]

    if (lastTrackedRoute.current === asPathWithoutParams) {
      // this was the last page that we have tracked - avoid duplication
      return
    }

    lastTrackedRoute.current = asPathWithoutParams

    trackInfOnline(metadata, trackingConfig, station)
  }, [metadata, comingBackFromArticleVideo, station, router.asPath])
}

export const useInfOnlineTracking = (metadata: Metadata | undefined) => {
  const { station } = useBroadcastInfo()

  return useCallback(() => {
    if (!metadata || !hasTrackingConsent()) {
      return
    }

    trackInfOnline(metadata, trackingConfig, station)
  }, [metadata, station])
}

export default usePageTracking
