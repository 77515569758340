import styled from 'styled-components'

const AppBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 1280px;
  height: 720px;
  z-index: 0;
  background-color: ${({ theme }) => theme.palette.background.main};
`

const Background = () => {
  return <AppBackground />
}

export default Background
