import ReactGA from 'react-ga4'
import { useCallback, useMemo, useRef } from 'react'
import { useRouter } from 'next/router'
import { hasTrackingConsent } from '@lib/utils/consent'
import { useBroadcastInfo } from '@lib/hooks/useBroadcastInfo'

export function useGoogleTag() {
  const isServerSide = typeof window === 'undefined'
  const isGoogleTagTrackingActive = !isServerSide && hasTrackingConsent()
  const { station: tv_channel } = useBroadcastInfo()

  const commonFields = useMemo(
    () => ({
      hbbtv_app: `${tv_channel}_digitaltext_v2`,
      tv_channel,
    }),
    [tv_channel],
  )

  // must be called after each route change, otherwise send_page_view is not working
  // unless Enhanced Measurements are disabled in GA4 settings.
  // @see https://github.com/codler/react-ga4/issues/27
  const initializeGoogleTag = useCallback(() => {
    if (isGoogleTagTrackingActive) {
      ReactGA.initialize('G-DXWHKVGN7Z', {
        gtagOptions: {
          send_page_view: false,
        },
      })
    }
  }, [isGoogleTagTrackingActive])

  const lastTrackedRoute = useRef<string | null>(null)
  const router = useRouter()

  const trackPageView = useCallback(
    (ivw: string): void => {
      if (
        isGoogleTagTrackingActive &&
        lastTrackedRoute.current !== router.asPath
      ) {
        lastTrackedRoute.current = router.asPath
        initializeGoogleTag()
        ReactGA.gtag('event', 'page_view', {
          ...commonFields,
          page_location: ivw,
        })
      }
    },
    [
      isGoogleTagTrackingActive,
      router.asPath,
      initializeGoogleTag,
      commonFields,
    ],
  )

  const trackVideoStarted = useCallback(
    ({
      media_id,
      media_title,
      media_tags,
      ivw,
    }: {
      media_id: string
      media_title: string
      media_tags: string
      ivw: string
    }) => {
      if (isGoogleTagTrackingActive) {
        initializeGoogleTag()
        ReactGA.gtag('event', 'video_started', {
          ...commonFields,
          page_location: ivw,
          media_id,
          media_title,
          media_tags,
        })
      }
    },
    [isGoogleTagTrackingActive, initializeGoogleTag, commonFields],
  )

  const trackTeaserClicked = useCallback(
    (content_id: string, content_title: string, ivw: string) => {
      if (isGoogleTagTrackingActive) {
        initializeGoogleTag()
        ReactGA.gtag('event', 'teaser_clicked', {
          ...commonFields,
          page_location: ivw,
          content_type: 'video',
          content_id,
          content_title,
        })
      }
    },
    [isGoogleTagTrackingActive, initializeGoogleTag, commonFields],
  )

  return useMemo(
    () => ({ trackPageView, trackVideoStarted, trackTeaserClicked }),
    [trackPageView, trackVideoStarted, trackTeaserClicked],
  )
}
