import React, {
  createContext,
  useLayoutEffect,
  useMemo,
  useState,
  useContext,
} from 'react'
import { useRouter } from 'next/router'

const PlayerConfigurationContext = createContext<{
  playerType?: string
}>({ playerType: 'av' })

export default function PlayerConfigurationProvider({
  children,
}: {
  children: React.ReactNode
}) {
  const [playerType, setPlayerType] = useState<'av' | 'html5'>('av')

  const router = useRouter()
  useLayoutEffect(() => {
    if (router.query.playerType === 'html5') {
      setPlayerType('html5')
    }
  }, [router.query])

  return (
    <PlayerConfigurationContext.Provider
      value={useMemo(() => ({ playerType }), [playerType])}
    >
      {children}
    </PlayerConfigurationContext.Provider>
  )
}

export function usePlayerConfiguration() {
  return useContext(PlayerConfigurationContext)
}
