import React, { Component, ReactNode } from 'react'
import ErrorDialog from '@components/shared/ErrorDialog'
import { bugsnag } from '@lib/bugsnag'

interface Props {
  children?: ReactNode
}

interface State {
  hasError: boolean
}

class ErrorBoundary extends Component<Props, State> {
  state: State = {
    hasError: false,
  }

  constructor(props: any) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(_: Error): State {
    return { hasError: true }
  }

  componentDidCatch(error: Error) {
    bugsnag?.notify(error)
  }

  render() {
    if (this.state.hasError) {
      return <ErrorDialog />
    }

    return this.props.children
  }
}

export default ErrorBoundary
