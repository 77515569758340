import { createGlobalStyle } from 'styled-components'

const ThemeGlobalStyle = createGlobalStyle`
    html,
    body {
      padding: 0;
      margin: 0;
      font-family: 'Tiresias', sans-serif;
      color: ${({ theme }) => theme.palette.primary.text};
    }

    body {
        margin: 0;
        width: 1280px;
        height: 720px;
        overflow: hidden;
        position: absolute;
        left: 0;
        top: 0;
    }

    a {
      color: inherit;
      text-decoration: none;
    }

    * {
      outline: none;
      box-sizing: border-box;
    }

    *:focus {
      outline: none;
    }

    *:focus-visible {
      outline: none;
    }

    h2 {
      font-size: ${({ theme }) => theme.typography.typo20.fontSize};
    }

    #player-fullscreen-portal {
      z-index: 102;
      position: absolute;
    }

    #videodiv {
      position: absolute;
      z-index: 101;
      overflow: hidden;
      background: black;
      display: none;
      
      top: 0px;
      left: 0px;
      width: 1280px;
      height: 720px;
    }

    #video {
      position: absolute;
    }
`

export default ThemeGlobalStyle
