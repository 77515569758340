import extractQueryString from './extractQueryString'

const toQueryString = (object: Record<string, any>) =>
  Object.keys(object)
    .filter((key) => object[key] !== null && typeof object[key] !== 'undefined')
    .map((key) => `${key}=${object[key].toString()}`)
    .join('&')

const buildUri = (url: string, query: Record<string, any> = {}) => {
  if (Object.keys(query).length === 0) {
    return url
  }

  const queryString = toQueryString(query)
  return queryString.length === 0 ? url : `${url}?${queryString}`
}

export function stringifyUrl(
  url: string,
  query: Record<string, any> = {},
): string {
  const urlQuery = extractQueryString(url)

  const mergedQuery = {
    ...urlQuery,
    ...query,
  }

  if (url.indexOf('?') === -1) {
    return buildUri(url, mergedQuery)
  }

  const urlWithoutQuery = url.substring(0, url.indexOf('?'))

  return buildUri(urlWithoutQuery, mergedQuery)
}
