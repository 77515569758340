var szmvars = ''
if (typeof window !== 'undefined') {
  window.iom = (function () {
    var e,
      t,
      n = ['imarex'],
      o = ['at'],
      i = [
        '',
        'inst',
        'init',
        'open',
        'clse',
        'play',
        'resm',
        'stop',
        'fowa',
        'bakw',
        'recd',
        'paus',
        'forg',
        'bakg',
        'dele',
        'refr',
        'kill',
        'view',
        'alve',
        'fini',
        'mute',
        'aforg',
        'abakg',
        'aclse',
        'sple',
        'scvl',
        'serr',
        'spyr',
        'smdr',
        'sfpl',
        'sfqt',
        'ssqt',
        'stqt',
        'soqt',
        'sofc',
        'scfc',
        'scqt',
        'splr',
        'spli',
        'sprs',
        'spre',
        'smrs',
        'smre',
        'sors',
        'sore',
        'sack',
        'sapl',
        'sapa',
        'snsp',
      ],
      r = [],
      a = '',
      s = 'Leercode_nichtzuordnungsfaehig',
      c = { onfocus: 'aforg', onblur: 'abakg', onclose: 'aclse' },
      l = [],
      p = 0,
      u = 6e4,
      m = null,
      d = null,
      f = {},
      g = {},
      h = 0,
      v = 0,
      w = 0,
      y = X(location.hostname),
      _ = [730, 785],
      b = !1,
      k = new Date()
    k.setDate(k.getDate() + 28)
    var C = {
      name: 'iom_consent',
      domain: y.length > 0 ? y.slice(7, y.length - 1) : '',
      expires: k.toUTCString(),
      path: '/',
    }
    function A(e) {
      T(e, { vendors: _, cookie: C, resultKey: 'ct' }, g)
    }
    function S(e, t) {
      var n = Date.now(),
        o = ''
      Object.keys(t).forEach(function (i, r, a) {
        var s = t[i]
        'name' === i
          ? ((o += s + '=' + e + '&' + n), (o += r < a.length ? '; ' : ''))
          : s && ((o += i + '=' + s), (o += r < a.length ? '; ' : ''))
      }),
        (document.cookie = o)
    }
    function T(e, t, n) {
      function o(e, t) {
        for (var n = '', o = 0, i = e.length; o < i; o += 1) n += '0000'
        return (n = (t ? '01' : '00') + n)
      }
      function i(e, n, i) {
        return function (r, a) {
          if (
            a &&
            ['tcloaded', 'useractioncomplete'].indexOf(r.eventStatus) > -1
          ) {
            var s = r.gdprApplies
              ? (function (e, t) {
                  function n(t, n, o) {
                    function i(e) {
                      return function (t) {
                        return !0 === e[t]
                      }
                    }
                    function r(e) {
                      return function (t) {
                        var n = parseInt(t) + e
                        return Math.pow(2, n)
                      }
                    }
                    var a,
                      s,
                      c,
                      l = []
                    return (
                      (a = Object.keys(e.purpose.consents)
                        .filter(i(e.purpose.consents))
                        .map(r(-1))),
                      n &&
                        (l = Object.keys(e.purpose.legitimateInterests)
                          .filter(i(e.purpose.legitimateInterests))
                          .map(r(-1))),
                      l.length > 0 &&
                        ((c = l),
                        (a = (s = a).concat(
                          c.filter(function (e) {
                            return s.indexOf(e) < 0
                          }),
                        ))),
                      o &&
                        (a = a.concat(
                          Object.keys(e.specialFeatureOptins)
                            .filter(i(e.specialFeatureOptins))
                            .map(r(9)),
                        )),
                      a
                    )
                  }
                  function o(e) {
                    for (var t = 0, n = 0, o = e.length; n < o; n += 1)
                      t |= e[n]
                    return t
                  }
                  for (var i = [1], r = 0, a = t.length; r < a; r += 1) {
                    var s = t[r]
                    if (
                      !0 === e.vendor.consents[s] ||
                      !0 === e.vendor.legitimateInterests[s]
                    ) {
                      var c,
                        l = e.vendor.legitimateInterests[s],
                        p = Object.keys(e.specialFeatureOptins).length > 0
                      ;(c = n(t[r], l, p)), i.push(o(c))
                    } else i.push(0)
                  }
                  return (function (e) {
                    function t(e, t) {
                      for (; e.length < t; ) e = '0' + e
                      return e
                    }
                    for (var n = '', o = 0, i = e.length; o < i; o += 1) {
                      var r = e[o].toString(16),
                        a = 4
                      0 === o && (a = 2), (n += r = t(r, a))
                    }
                    return n
                  })(i)
                })(r, n.vendors)
              : o(n.vendors, !0)
            s !== e &&
              (i && n.resultKey && (i[n.resultKey] = s), S(s, t.cookie)),
              __tcfapi('removeEventListener', 2, function () {}, r.listenerId)
          } else {
            var c = o(n.vendors, !0)
            i && n.resultKey && (i[n.resultKey] = c), S(c, t.cookie)
          }
        }
      }
      function r() {
        return '__tcfapi' in window
      }
      var a,
        s,
        c,
        l,
        p,
        u = 0,
        m = 0,
        d = ((a = t.cookie),
        (l = ''),
        (p = document.cookie.match(new RegExp('(^| )' + a.name + '=([^;]+)'))),
        p && ((l = (c = p[2].split('&'))[0]), (s = c[1])),
        { value: l, date: s }).value,
        f = o(t.vendors, r())
      r()
        ? (n && t.resultKey && (n[t.resultKey] = d || f),
          __tcfapi('addEventListener', 2, i(d || f, t, n)),
          !1 === b &&
            (__tcfapi(
              'addEventListener',
              2,
              (function (e, t, n) {
                return function (o, r) {
                  r &&
                    'cmpuishown' === o.eventStatus &&
                    __tcfapi('addEventListener', 2, i(e, t, n))
                }
              })(d || f, t, n),
            ),
            (b = !0)))
        : r() ||
          (u = setInterval(function () {
            ;(m += 1), (r() || m >= 10) && (clearInterval(u), T(e, t, n))
          }, 60)),
        e && e !== d && !1 === r()
          ? (S(e, t.cookie), n && t.resultKey && (n[t.resultKey] = e))
          : !e && d && !1 === r()
          ? n && t.resultKey && (n[t.resultKey] = d)
          : e ||
            d ||
            !1 !== r() ||
            (S(f, t.cookie), n && t.resultKey && (n[t.resultKey] = f))
    }
    var I = function (e) {
      e &&
        e.hasOwnProperty('block-status') &&
        'NONE' === e['block-status'].toUpperCase() &&
        (d && d.parentNode.removeChild(d), (d = O(e['invite-url'])))
    }
    function F(e) {
      for (var t = 0, n = 0; n < e.length; ++n)
        (t += e.charCodeAt(n)), (t += t << 10), (t ^= t >> 6)
      return (
        (t += t << 3),
        (t ^= t >> 11),
        (t += t << 15),
        (t = Math.abs(t & t)).toString(36)
      )
    }
    function D() {
      var e = window.navigator,
        t = e.userAgent
      if (((t += x()), e.plugins.length > 0))
        for (var n = 0; n < e.plugins.length; n++)
          t +=
            e.plugins[n].filename +
            e.plugins[n].version +
            e.plugins[n].description
      if (e.mimeTypes.length > 0)
        for (n = 0; n < e.mimeTypes.length; n++) t += e.mimeTypes[n].type
      if (/MSIE (\d+\.\d+);/.test(e.userAgent))
        try {
          t += (function () {
            var e,
              t = '',
              n = [
                '7790769C-0471-11D2-AF11-00C04FA35D02',
                '89820200-ECBD-11CF-8B85-00AA005B4340',
                '283807B5-2C60-11D0-A31D-00AA00B92C03',
                '4F216970-C90C-11D1-B5C7-0000F8051515',
                '44BBA848-CC51-11CF-AAFA-00AA00B6015C',
                '9381D8F2-0288-11D0-9501-00AA00B911A5',
                '4F216970-C90C-11D1-B5C7-0000F8051515',
                '5A8D6EE0-3E18-11D0-821E-444553540000',
                '89820200-ECBD-11CF-8B85-00AA005B4383',
                '08B0E5C0-4FCB-11CF-AAA5-00401C608555',
                '45EA75A0-A269-11D1-B5BF-0000F8051515',
                'DE5AED00-A4BF-11D1-9948-00C04F98BBC9',
                '22D6F312-B0F6-11D0-94AB-0080C74C7E95',
                '44BBA842-CC51-11CF-AAFA-00AA00B6015B',
                '3AF36230-A269-11D1-B5BF-0000F8051515',
                '44BBA840-CC51-11CF-AAFA-00AA00B6015C',
                'CC2A9BA0-3BDD-11D0-821E-444553540000',
                '08B0E5C0-4FCB-11CF-AAA5-00401C608500',
                'D27CDB6E-AE6D-11CF-96B8-444553540000',
                '2A202491-F00D-11CF-87CC-0020AFEECF20',
              ]
            document.body.addBehavior('#default#clientCaps')
            for (var o = 0; o < n.length; o++)
              t +=
                null !==
                (e = document.body.getComponentVersion(
                  '{' + n[o] + '}',
                  'ComponentID',
                ))
                  ? e
                  : 'null'
            return t
          })()
        } catch (e) {}
      return F(t)
    }
    function O(e) {
      var t = document.createElement('script')
      ;(t.type = 'text/javascript'), (t.src = e)
      var n = document.getElementsByTagName('head')[0]
      return !!n && (n.appendChild(t), t)
    }
    function N(e, t) {
      if (
        '.ioam.de' == e.split('/')[2].slice(e.split('/')[2].length - 8) ||
        '.iocnt.net' == e.split('/')[2].slice(e.split('/')[2].length - 10)
      )
        switch (t) {
          case 1:
            m && m.parentNode.removeChild(m),
              (m = O(e + '&mo=1')) || (new Image().src = e + '&mo=0')
            break
          case 2:
            new Image().src = e + '&mo=0'
            break
          case 3:
            !(function (e) {
              function t(e) {
                var t = document.createElement('iframe')
                ;(t.className = 'iamsendbox'),
                  (t.style.position = 'absolute'),
                  (t.style.left = t.style.top = '-999px'),
                  (t.src = e + '&mo=1'),
                  document.body.appendChild(t)
              }
              var n = document.querySelectorAll('.iamsendbox')
              n.length < 10 || n[0].remove(), t(e)
            })(e)
            break
          case 0:
          default:
            document.write('<script src="' + e + '&mo=1"></script>')
        }
    }
    function x() {
      return screen.width + 'x' + screen.height + 'x' + screen.colorDepth
    }
    function E(e, t) {
      var n
      for (n = 0; n < e.length; n++) if (e[n] == t) return !0
      return !1
    }
    function B(e) {
      return (
        e || (e = ''),
        (e = (e = e.replace(/[?#].*/g, '')).replace(/[^a-zA-Z0-9,_\/-]+/g, '.'))
          .length > 255 && (e = e.substr(0, 254) + '+'),
        e
      )
    }
    function P(e) {
      var t, i, r
      for (t in ((g = {}), e))
        e.hasOwnProperty(t) &&
          ('cn' != t || ('cn' == t && E(n, e[t])) || E(o, e[t])) &&
          (g[t] = e[t])
      if (
        (g.hasOwnProperty('fp') &&
          ((g.fp = '' != g.fp && void 0 !== g.fp ? g.fp : s),
          (g.fp = B(g.fp)),
          (g.pt = 'FP')),
        g.hasOwnProperty('np') &&
          ((g.np = '' != g.np && void 0 !== g.np ? g.np : s),
          (g.np = B(g.np)),
          (g.pt = 'NP')),
        g.hasOwnProperty('xp') &&
          ((g.xp = '' != g.xp && void 0 !== g.xp ? g.xp : s),
          (g.xp = B(g.xp)),
          (g.pt = 'XP')),
        g.hasOwnProperty('cp') &&
          ((g.cp = '' != g.cp && void 0 !== g.cp ? g.cp : s),
          (g.cp = B(g.cp)),
          (g.pt = 'CP')),
        g.hasOwnProperty('ms') &&
          (g.ms = '' != g.ms && void 0 !== g.ms ? g.ms : ''),
        g.pt || ((g.cp = s), (g.pt = 'CP'), (g.er = 'N13')),
        g.hasOwnProperty('ps')
          ? E(['ack', 'lin', 'pio', 'out'], g.ps) ||
            ((g.ps = 'lin'), (g.er = 'N23'))
          : ((g.ps = 'lin'), (g.er = 'N22')),
        (g.rf = (i = document.referrer.split('/')).length >= 3 ? i[2] : ''),
        (!g.hasOwnProperty('sur') ||
          (g.hasOwnProperty('sur') && 'yes' != g.sur)) &&
          (g.r2 =
            ((r = document.referrer) || (r = ''),
            (r = r.replace(/[^a-zA-Z0-9,_\/:-]+/g, '.')).length > 255 &&
              (r = r.substr(0, 254) + '+'),
            r)),
        (g.ur = document.location.host),
        (g.xy = x()),
        (g.cb = '8012'),
        (g.vr = '424'),
        (g.id = D()),
        (g.st = g.st ? g.st : 'dummy'),
        !g.hasOwnProperty('sc') || (g.hasOwnProperty('sc') && 'no' != g.sc))
      ) {
        var a = V()
        ;(g.i3 = a.cookie), (g.n1 = a.length)
      }
      if (
        ((E(l, g.st) || (g.hasOwnProperty('sc') && 'yes' == g.sc)) &&
          'nocookie' == g.i3 &&
          (g.i3 = Z()),
        !g.hasOwnProperty('cn') && '_' == g.st.charAt(2))
      ) {
        var c = g.st.substr(0, 2)
        E(n, c) || E(o, c) ? (g.cn = c) : (g.er = 'E12')
      }
      try {
        g.dntt =
          (window.navigator.msDoNotTrack &&
            '1' == window.navigator.msDoNotTrack) ||
          (window.navigator.doNotTrack &&
            ('yes' == window.navigator.doNotTrack ||
              '1' == window.navigator.doNotTrack))
            ? '1'
            : '0'
      } catch (e) {}
    }
    function j(a) {
      var s,
        c = ''
      if (
        ((a = a || ''),
        (function () {
          try {
            clearInterval(e)
          } catch (e) {}
        })(),
        w &&
          !(function () {
            if (void 0 !== g.nt && g.nt) {
              if (
                window.navigator.msDoNotTrack &&
                '1' == window.navigator.msDoNotTrack
              )
                return !0
              if (
                window.navigator.doNotTrack &&
                ('yes' == window.navigator.doNotTrack ||
                  '1' == window.navigator.doNotTrack)
              )
                return !0
            }
            return !1
          })() &&
          E(i, a) &&
          'out' !== g.ps)
      ) {
        ;(g.lt = new Date().getTime()), (g.ev = a)
        var l = 'de.ioam.de/tx.io'
        for (s in (g.cn && E(n, g.cn)
          ? (l = g.cn + '.ioam.de/tx.io')
          : g.cn && E(o, g.cn) && (l = g.cn + '.iocnt.net/tx.io'),
        !E(r, g.st) &&
          (((/iPhone/.test(window.navigator.userAgent) ||
            /iPad/.test(window.navigator.userAgent)) &&
            /Safari/.test(window.navigator.userAgent) &&
            !/Chrome/.test(window.navigator.userAgent) &&
            !/CriOS/.test(window.navigator.userAgent)) ||
            /Maple_201/.test(window.navigator.userAgent) ||
            /SMART-TV/.test(window.navigator.userAgent) ||
            /SmartTV201/.test(window.navigator.userAgent)) &&
          ((l =
            g.cn && E(n, g.cn)
              ? g.cn + '.ioam.de/aid.io'
              : g.cn && E(o, g.cn)
              ? g.cn + '.iocnt.net/aid.io'
              : 'de.ioam.de/aid.io'),
          (t = 3),
          g.hasOwnProperty('sur') && 'yes' == g.sur
            ? (g.u2 = window.location.origin)
            : (g.u2 = document.URL)),
        g))
          g.hasOwnProperty(s) &&
            'cs' != s &&
            'url' != s &&
            (c =
              c +
              encodeURIComponent(s).slice(0, 8) +
              '=' +
              encodeURIComponent(g[s]).slice(0, 2048) +
              '&')
        return (
          (c = c.slice(0, 4096)),
          (g.cs = F(c)),
          (g.url = 'https://' + l + '?' + c + 'cs=' + g.cs),
          N(g.url, t),
          E(
            [
              'play',
              'resm',
              'alve',
              'mute',
              'sfqt',
              'ssqt',
              'stqt',
              'sapl',
              'snsp',
            ],
            a,
          ) &&
            (1 === t || 3 === t) &&
            g.hasOwnProperty('hb') &&
            L(),
          g
        )
      }
      return {}
    }
    function M(e, t) {
      return z(e, t), j(g.ev)
    }
    function z(e, n) {
      return (
        (e.cn && 'at' === e.cn) ||
          T(e.ct, { vendors: _, cookie: C, resultKey: 'ct' }, e),
        (t = n),
        e.act && delete e.act,
        P(e),
        g.sv && (g.sv = 'in' == g.sv && 1 == t ? 'i2' : g.sv),
        g.sv &&
          'ke' !== g.sv &&
          !1 ===
            (function (e, t, n, o, i) {
              var r = !1
              if ('string' == typeof e && e.length === 2 + 4 * t.length) {
                var a = t.indexOf(n)
                if (a > -1) {
                  var s = 2 + 4 * (a + 1),
                    c = parseInt(e.slice(2, s), 16),
                    l = Math.pow(2, o + i)
                  r = (c & l) === l
                }
              }
              return r
            })(e.ct, _, 785, 9, -1) &&
          (g.sv = 'ke'),
        (function () {
          if ('on' == g.tb && 'off' != g.tb && !h)
            for (var e in ((h = 1), (t = 1), c))
              !(function (e) {
                var t = window[e]
                window[e] = function () {
                  a != c[e] && ((a = c[e]), j(c[e])),
                    'function' == typeof t && t()
                }
              })(e)
        })(),
        (function () {
          szmvars = g.st + '//' + g.pt + '//' + g.cp + '//VIA_SZMNG'
          var e = 'i2' == g.sv ? 'in' : g.sv,
            t = 'irqs.ioam.de'
          if (
            (g.cn &&
              ((e += '_' + g.cn), 'at' == g.cn && (t = 'irqs.iocnt.net')),
            (f = {
              siteIdentifier: g.cp,
              offerIdentifier: g.st,
              sampleType: e,
              pixelType: g.pt,
              contentType: g.cp,
              host: t,
              port: '',
              isFadeoutFlash: !0,
              isFadeoutFrame: !0,
              isFadeoutForm: !0,
              positionTop: 10,
              positionLeft: 100,
              zIndex: 11e5,
              popupBlockDuration: 864e5,
              keysForQueryParam: [
                'offerIdentifier',
                'siteIdentifier',
                'sampleType',
                'pixelType',
                'isFadeoutFlash',
                'isFadeoutFrame',
                'isFadeoutForm',
                'positionTop',
                'positionLeft',
                'zIndex',
              ],
            }),
            void 0 !== window.iam_zindex && (f.zIndex = window.iam_zindex),
            void 0 !== window.iam_fadeout_flash &&
              (f.isFadeoutFlash = window.iam_fadeout_flash),
            void 0 !== window.iam_fadeout_iframe &&
              (f.isFadeoutFrame = window.iam_fadeout_iframe),
            void 0 !== window.iam_fadeout_form &&
              (f.isFadeoutForm = window.iam_fadeout_form),
            void 0 !== window.iam_position_top &&
              (f.positionTop = window.iam_position_top),
            void 0 !== window.iam_position_left &&
              (f.positionLeft = window.iam_position_left),
            !(
              (function () {
                for (
                  var e = document.cookie.split(';'), t = 0;
                  t < e.length;
                  t++
                )
                  if (e[t].match('POPUPCHECK=.*')) {
                    var n = new Date(),
                      o = n.getTime()
                    if ((n.setTime(e[t].split('=')[1]), o <= n.getTime()))
                      return !0
                  }
                return !1
              })() ||
              (v || 'ke' === g.sv || 'dz' !== g.sv || ((v = 1), iam_ng_nxss()),
              v ||
                'ke' === g.sv ||
                ('in' !== g.sv && 'mo' !== g.sv && 'i2' !== g.sv))
            ))
          ) {
            ;(v = 1),
              (function (e) {
                var t = new Date()
                t.setTime(t.getTime() + e)
                var n = 'expires=' + t.toUTCString()
                document.cookie =
                  'POPUPCHECK=' + t.getTime().toString() + ';' + n + ';path=/'
              })(f.popupBlockDuration)
            var n = 'identitystatus',
              o =
                '?' +
                (function (e) {
                  var t = []
                  for (var n in e)
                    e.hasOwnProperty(n) &&
                      t.push(
                        encodeURIComponent(n) + '=' + encodeURIComponent(e[n]),
                      )
                  return t.join('&')
                })(
                  (function (e, t) {
                    for (var n, o = {}, i = t.length, r = 0; r < i; r++)
                      (n = t[r]), e.hasOwnProperty(n) && (o[n] = e[n])
                    return o
                  })(f, f.keysForQueryParam),
                )
            if (window.XDomainRequest && 9 === document.documentMode)
              O(
                (i =
                  'https://' +
                  f.host +
                  '/identitystatus/identity.js' +
                  o +
                  '&callback=iom.gi&c=' +
                  Math.random()),
              )
            else {
              var i = 'https://' + f.host + '/' + n + o + '&c=' + Math.random(),
                r = new XMLHttpRequest()
              ;(r.onreadystatechange = function () {
                if (r.readyState === XMLHttpRequest.DONE && 200 === r.status) {
                  var e = JSON.parse(r.responseText)
                  I(e)
                }
              }),
                r.open('GET', i, !0),
                (r.withCredentials = !0),
                r.send(null)
            }
          }
        })(),
        (function () {
          try {
            for (var e = document.cookie.split(';'), t = 0; t < e.length; t++)
              if (e[t].match('ioamout=.*')) return (g.ps = 'out'), !0
          } catch (e) {
            return !1
          }
        })(),
        (w = 1),
        (function () {
          if ('yes' === g.oer && !window.IVW && !document.IVW) {
            var e =
                'http' === window.location.protocol.slice(0, 4)
                  ? window.location.protocol
                  : 'https:',
              t = g.co
                ? g.co + '_SENT_VIA_MIGRATION_TAG'
                : 'SENT_VIA_MIGRATION_TAG',
              n = g.oc ? g.oc : g.cp ? (g.cp == s ? '' : g.cp) : '',
              o = null !== g.pt ? g.pt : 'CP'
            new Image().src =
              e +
              '//' +
              g.st +
              '.ivwbox.de/cgi-bin/ivw/' +
              o.toUpperCase() +
              '/' +
              n +
              ';' +
              t +
              '?r=' +
              escape(document.referrer) +
              '&d=' +
              1e5 * Math.random()
          }
        })(),
        {}
      )
    }
    function q(e, t) {
      z(e, t)
      var n =
          'object' == typeof localStorage &&
          'function' == typeof localStorage.getItem
            ? localStorage.getItem('ioam_smi')
            : null,
        o =
          'object' == typeof localStorage &&
          'function' == typeof localStorage.getItem
            ? localStorage.getItem('ioam_site')
            : null,
        i =
          'object' == typeof localStorage &&
          'function' == typeof localStorage.getItem
            ? localStorage.getItem('ioam_bo')
            : null
      return null !== n && null !== o && null !== i
        ? ((g.mi = n),
          (g.fs = g.st),
          (g.st = o),
          (g.bo = i),
          g.fs == g.st
            ? (g.cp =
                '___hyb2___' !== g.cp.slice(0, 10)
                  ? '___hyb2___' + g.fs + '___' + g.cp
                  : g.cp)
            : (g.cp =
                '___hyb___' !== g.cp.slice(0, 9)
                  ? '___hyb___' + g.fs + '___' + g.cp
                  : g.cp),
          j(g.ev))
        : (null !== n && null !== i) ||
          'http' !== window.location.protocol.slice(0, 4) ||
          /IOAM\/\d+\.\d+/.test(window.navigator.userAgent)
        ? {}
        : j(g.ev)
    }
    function K(e) {
      if (
        null === localStorage.getItem('ioam_smi') ||
        null === localStorage.getItem('ioam_site') ||
        null === localStorage.getItem('ioam_bo') ||
        localStorage.getItem('ioam_smi') !== e
      ) {
        g.fs = g.st
        var t = null,
          n = null
        if (
          'string' == typeof e &&
          'object' == typeof JSON &&
          'function' == typeof JSON.parse
        )
          try {
            ;(t = JSON.parse(e)).hasOwnProperty('library')
              ? t.library.hasOwnProperty('offerIdentifier')
                ? t.library.offerIdentifier
                  ? (n = t.library.offerIdentifier)
                  : (g.er = 'JSON(E10): offerIdentifier not valid')
                : (g.er = 'JSON(E10): no key offerIdentifier')
              : (g.er = 'JSON(E10): no key library')
          } catch (e) {
            g.er = 'JSON(E10): ' + e
          }
        return (
          null !== n && localStorage.setItem('ioam_site', n),
          (g.st = n),
          (g.mi = e),
          (g.bo = new Date().getTime()),
          localStorage.setItem('ioam_smi', g.mi),
          localStorage.setItem('ioam_bo', g.bo),
          g.fs == g.st
            ? (g.cp =
                '___hyb2___' !== g.cp.slice(0, 10)
                  ? '___hyb2___' + g.fs + '___' + g.cp
                  : g.cp)
            : (g.cp =
                '___hyb___' !== g.cp.slice(0, 9)
                  ? '___hyb___' + g.fs + '___' + g.cp
                  : g.cp),
          j(g.ev)
        )
      }
      return {}
    }
    if (
      window.postMessage ||
      (window.JSON &&
        '[object Function]' !== {}.toString.call(window.JSON.parse) &&
        '[object Function]' !== {}.toString.call(window.JSON.stringify))
    ) {
      var R = function (e) {
        try {
          var t = JSON.parse(e.data)
        } catch (e) {
          t = { type: !1 }
        }
        if ('[object Object]' === {}.toString.call(t) && 'iam_data' == t.type) {
          var n = { seq: t.seq, iam_data: { st: g.st, cp: g.cp } }
          e.source.postMessage(JSON.stringify(n), e.origin)
        }
      }
      window.addEventListener
        ? window.addEventListener('message', R)
        : window.attachEvent('onmessage', R)
    }
    function U() {
      var e =
        'http' === window.location.protocol.slice(0, 4)
          ? window.location.protocol
          : 'https://de.ioam.de/optin.php?re='
      window.open(e, '_blank').focus()
    }
    function L() {
      switch (g.hb) {
        case 'adshort':
          u = 5e3
          break
        case 'admedium':
          u = 1e4
          break
        case 'adlong':
          u = 3e4
          break
        case 'short':
          u = 1e4
          break
        case 'medium':
          u = 3e4
          break
        case 'long':
          u = 6e4
          break
        case 'extralong':
          u = 3e5
          break
        default:
          u = 0
      }
      if (0 != u)
        try {
          e = setInterval(function () {
            return j('alve')
          }, u)
        } catch (e) {}
    }
    function J() {
      var e = 999999999999,
        t = 1e11
      return (
        (Math.floor(Math.random() * (e - t + 1)) + t).toString(16) +
        (Math.floor(Math.random() * (e - t + 1)) + t).toString(16) +
        (function (e) {
          for (var t = [], n = 0, o = e.length; n < o; n++) {
            var i = Number(e.charCodeAt(n)).toString(16)
            t.push(i)
          }
          return t.join('')
        })(g.cb) +
        (Math.floor(Math.random() * (e - t + 1)) + t).toString(16)
      )
    }
    function V() {
      try {
        for (var e = document.cookie.split(';'), t = 0; t < e.length; t++)
          if (e[t].match('ioam2018=.*')) {
            var n = e[t].split('=')[1].replace('!', ':'),
              o = n.split(':'),
              i = o.slice(0, o.length - 1).join(':'),
              r = o.slice(-1).pop()
            if (F(i) === r)
              return (
                (g.hasOwnProperty('i3') && g.i3) || H(n),
                { cookie: n, length: e.length }
              )
            g.er = 'N19'
            try {
              p < 3 ? (p++, Z(2e3)) : (g.er = 'N20')
            } catch (e) {
              g.er = 'N20'
            }
          }
      } catch (e) {
        return { cookie: 'nocookie', length: 0 }
      }
      return { cookie: 'nocookie', length: e.length }
    }
    function G() {
      return 'nocookie' != V().cookie
    }
    function X(e) {
      var t,
        n =
          'acadaeafagaialamaoaqarasatauawaxazbabbbdbebfbgbhbibjbmbnbobrbsbtbwbybzcacccdcfcgchcickclcmcncocrcucvcwcxcyczdjdkdmdodzeceeegereseteufifjfkfmfofrgagdgegfggghgiglgmgngpgqgrgsgtgugwgyhkhmhnhrhthuidieiliminioiqirisitjejmjojpkekgkhkikmknkpkrkwkykzlalblclilklrlsltlulvlymamcmdmemgmhmkmlmmmnmompmqmrmsmtmumvmwmxmymznancnenfngninlnonpnrnunzompapepfpgphpkplpmpnprpsptpwpyqarerorsrurwsasbscsdsesgshsiskslsmsnsosrssstsvsxsysztctdtftgthtjtktltmtntotrtttvtwtzuaugukusuyuzvavcvevgvivnvuwfwsyeytzazmzw'.match(
            /.{1,2}(?=(.{2})+(?!.))|.{1,2}$/g,
          ),
        o = ['www', 'm', 'mobile'],
        i = e.split('.'),
        r = [],
        a = [],
        s = '',
        c = '',
        l = 0,
        p = 0
      if (!e) return ''
      if (E(n, i[i.length - 1])) {
        for (l = i.length - 1; l >= 0; l -= 1) {
          if (!(l >= i.length - 3 && i[l].length <= 4)) {
            a.push(i[l])
            break
          }
          r.push(i[l])
        }
        for (l = 0, p = (r = r.reverse()).length; l < p; l += 1)
          E(o, r[l]) || (s += l < p ? '.' + r[l] : r[l])
        E(o, (c = (a = a.reverse())[a.length - 1] || '')) && (c = '')
      } else c = i.slice(i.length - 2, i.length).join('.') || ''
      return (t = c + s) && t.length > 4 && t.split('.').length > 1
        ? 'domain=' + ('.' === t[0] ? t : t ? '.' + t : '') + ';'
        : ''
    }
    function H(e) {
      var t = X(location.hostname),
        n = e.split(':')[1],
        o = parseInt(e.split(':')[4]) + 1,
        i = new Date(new Date().setTime(n)),
        r = new Date(),
        a = g.st ? g.st : 'nosite',
        s = g.cp ? g.cp : g.np ? g.np : g.fp ? g.fp : 'nocode',
        c = g.ev ? g.ev : 'noevent',
        l =
          e.split(':').slice(0, 4).join(':') +
          ':' +
          o +
          ':' +
          a +
          ':' +
          s +
          ':' +
          c +
          ':' +
          r.getTime().toString()
      ;(l = l + ':' + F(l)),
        (document.cookie =
          'ioam2018=' +
          l +
          ';expires=' +
          i.toUTCString() +
          ';' +
          t +
          ';path=/;')
    }
    function Z(e) {
      e || (e = 24 * (Math.floor(66 * Math.random()) + 300) * 60 * 60 * 1e3)
      var t,
        n = X(location.hostname),
        o = new Date(new Date().setTime(new Date().getTime() + e)),
        i = new Date(),
        r = g.st ? g.st : 'nosite',
        a = g.cp ? g.cp : g.np ? g.np : g.fp ? g.fp : 'nocode',
        s = g.ev ? g.ev : 'noevent',
        c =
          (t = g.hasOwnProperty('i2') ? g.i2 : J()) +
          ':' +
          o.getTime().toString() +
          ':' +
          i.getTime().toString() +
          ':' +
          n.replace('domain=', '').replace(';', '') +
          ':1:' +
          r +
          ':' +
          a +
          ':' +
          s +
          ':' +
          i.getTime().toString(),
        l =
          t +
          ':' +
          o.getTime().toString() +
          ':' +
          i.getTime().toString() +
          ':' +
          n.replace('domain=', '').replace(';', '') +
          ':2:' +
          r +
          ':' +
          a +
          ':' +
          s +
          ':' +
          i.getTime().toString()
      return (
        (l = l + ':' + F(l)),
        (document.cookie =
          'ioam2018=' +
          l +
          ';expires=' +
          o.toUTCString() +
          ';' +
          n +
          ';path=/;'),
        G() ||
        ((document.cookie =
          'ioam2018=' + l + ';expires=' + o.toUTCString() + ';path=/;'),
        (g.er = 'N25'),
        G())
          ? c
          : ((g.er = 'N26'), 'nocookie')
      )
    }
    function Q(e) {
      e || (e = 864e5)
      var t = X(location.hostname),
        n = new Date(new Date().setTime(new Date().getTime() + e))
      ;(document.cookie =
        'ioamout=stop;expires=' + n.toUTCString() + ';' + t + ';path=/;'),
        Z(2e3)
    }
    function W() {
      Q(2e3), Z(2e3)
    }
    function $() {
      if (
        'object' == typeof localStorage &&
        'function' == typeof localStorage.getItem
      ) {
        if (
          null !== localStorage.getItem('ioamplusdata') &&
          null !== localStorage.getItem('ioamplusttl')
        ) {
          var e = new Date(),
            t = e.getTime()
          if (
            (e.setTime(localStorage.getItem('ioamplusttl')), t <= e.getTime())
          )
            return !0
        }
        var n =
            'https://me.ioam.de/soziodata2.php?sc=private&st=' +
            g.st +
            '&id=' +
            g.id,
          o =
            ((i = 'GET'),
            (r = n),
            'withCredentials' in (a = new XMLHttpRequest())
              ? (a.open(i, r, !0), (a.withCredentials = !0))
              : 'undefined' != typeof XDomainRequest
              ? (a = new XDomainRequest()).open(i, r)
              : (a = null),
            a)
        if (o)
          return (
            (o.onload = function () {
              var e = o.responseText,
                t = new Date()
              try {
                '0' == e.split(':')[1].split(',')[0]
                  ? (t.setTime(t.getTime() + 18e4),
                    localStorage.setItem('ioamplusttl', t.getTime().toString()),
                    null == localStorage.getItem('ioamplusdata') &&
                      localStorage.setItem('ioamplusdata', e))
                  : (t.setTime(t.getTime() + 864e5),
                    localStorage.setItem('ioamplusdata', e),
                    localStorage.setItem('ioamplusttl', t.getTime().toString()))
              } catch (e) {}
            }),
            o.send(),
            !0
          )
      }
      var i, r, a
      return !1
    }
    return {
      count: M,
      c: M,
      i: z,
      init: z,
      e: j,
      event: j,
      h: q,
      hybrid: q,
      setMultiIdentifier: K,
      smi: K,
      oi: U,
      optin: U,
      setoptout: Q,
      soo: Q,
      deloptout: W,
      doo: W,
      getInvitation: I,
      gi: I,
      getPlus: $,
      gp: $,
      consent: A,
      ct: A,
    }
  })()
}
