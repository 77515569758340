import { Keys, useHotKeys } from '@cbc/hbbtv-captain-hook'
import { useCallback } from 'react'
import { openLaunchbar } from '../openApplication'
import useNavigationContext from '../hooks/useNavigationContext'
import { useBroadcastInfo } from '@lib/hooks/useBroadcastInfo'

const GlobalHotKeys = () => {
  const { clearAndPush } = useNavigationContext()
  const broadcastInfo = useBroadcastInfo()

  const onRedButtonKeydown = useCallback(() => {
    openLaunchbar(broadcastInfo)
  }, [broadcastInfo])

  const onGreenButtonKeydown = useCallback(() => {
    openLaunchbar(broadcastInfo, {
      show: 'launchbar',
    })
  }, [broadcastInfo])

  const onYellowButtonKeydown = useCallback(() => {
    clearAndPush('/100')
  }, [clearAndPush])

  useHotKeys(
    {
      [Keys.BUTTON_RED]: onRedButtonKeydown,
      [Keys.BUTTON_GREEN]: onGreenButtonKeydown,
      [Keys.BUTTON_YELLOW]: onYellowButtonKeydown,
    },
    { global: true },
  )

  return null
}

export default GlobalHotKeys
