import React, { ElementType, ReactNode } from 'react'

type BugsnagErrorBoundaryProps = {
  Boundary?: ElementType
  children: ReactNode
}

const BugsnagErrorBoundary = ({
  Boundary,
  children,
}: BugsnagErrorBoundaryProps) =>
  Boundary === undefined ? <>{children}</> : <Boundary>{children}</Boundary>

export default BugsnagErrorBoundary
