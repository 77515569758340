import { Metadata, TrackingConfiguration } from './usePageTracking'
import { Station } from '@lib/hooks/useBroadcastInfo'
import '../../vendors/iam.min'

const trackInfOnline = (
  metadata: Metadata,
  trackingConfig: TrackingConfiguration,
  station: Station,
) => {
  const { agof, ivw } = metadata
  const { offer } = trackingConfig

  let st = offer[station]
  if (!st) {
    st = 'ctvrtlde'
    console.error(
      `No offer for station "${station}" found (check NEXT_PUBLIC_TRACKING_CONFIGURATION), ` +
        `using fallback value "${st}".`,
    )
  }

  const iamSzmVersion = 2
  const iamTrackingData = {
    st,
    cp: agof,
    co: ivw,
    ct: '0000180141',
  }

  try {
    // @ts-ignore
    window.iom.c(iamTrackingData, iamSzmVersion)
  } catch (error) {
    console.log('failed to track inf-online', error)
  }
}

export default trackInfOnline
