import { useEffect } from 'react'
import { broadcastObjectStyle } from '../OipfApplicationContainer'
import { isInitialized } from '@lib/hooks/useInitializeOipfApplication'

export const BROADCAST_ELEMENT_ID = 'broadcastVideo'

export let broadcastVisible = true

export function useHideBroadcast() {
  useEffect(() => {
    const broadcast = document.getElementById(BROADCAST_ELEMENT_ID)
    if (broadcast) {
      broadcast.style.visibility = 'hidden'
      broadcastVisible = false
    }
  }, [])
}

export function useShowBroadcast() {
  const initialized = isInitialized()
  useEffect(() => {
    // showing the broadcast too early breaks scaling on Samsung TVs
    if (!initialized) {
      return
    }

    const broadcast = document.getElementById(BROADCAST_ELEMENT_ID)
    if (broadcast) {
      broadcast.style.visibility = 'visible'
      broadcastVisible = true
    }
  }, [initialized])
}

export function useSuspendBroadcast() {
  useEffect(() => {
    const broadcast: any = document.getElementById(BROADCAST_ELEMENT_ID)

    if (!broadcast || typeof window === 'undefined') {
      return undefined
    }
    try {
      const origSvc = broadcast.currentChannel

      window['currentOipfChannel' as any] = origSvc

      broadcast.bindToCurrentChannel?.()
    } catch (e) {
      console.log(e)
    }

    try {
      broadcast.stop()
    } catch (e) {
      console.log('Suspending broadcast failed', e)
    }
    broadcast.style.visibility = 'hidden'

    window.onunload = function () {
      try {
        broadcast.bindToCurrentChannel?.()
      } catch (e) {
        console.log(e)
      }
    }

    return () => {
      /* 
      Panasonic TVs seem to forget the broadcast style 
      and therefore display the broadcast picture over the full 100% width and height. 
      To prevent this, we set the style again manually.
      */
      broadcast.style.top = broadcastObjectStyle.top
      broadcast.style.left = broadcastObjectStyle.left
      broadcast.style.width = broadcastObjectStyle.width
      broadcast.style.height = broadcastObjectStyle.height
      broadcast.style.visibility = 'visible'
      broadcastVisible = true
      try {
        broadcast.bindToCurrentChannel?.()
      } catch (e) {
        console.log(e)
      }
    }
  }, [])
}
