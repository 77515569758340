const extractQueryString = (uri: string): Record<string, string> => {
  if (uri.indexOf('?') === -1) {
    return {}
  }

  return uri
    .substring(uri.indexOf('?') + 1)
    .split('&')
    .reduce(
      (memo, param) => ({
        ...memo,
        [param.split('=')[0]]: param.split('=')[1],
      }),
      {},
    )
}

export default extractQueryString
